import React from "react";
import Video from "../../assets/kgf.mp4";
import "./AboutHome.css";
import { Link } from "react-router-dom";

function AboutHome() {
  return (
    <div>
      <div className="about-container">
        <div className="about-video">
        <video
            src={Video}
            controls
            style={{ border: "0", width: "100%" }}
            muted
            loop
          />
        </div>
        <div className="about-content">
          <br></br>
          <h2>About #Include</h2>
          <p>
            <b>#INCLUDE</b>, an annual regal celebration orchestrated by FOCUS,
            the esteemed student governance body of the Computer Science and
            Engineering department at KL University, is a symphony of vibrancy,
            innovation, and unwavering student leadership.
          </p>
          <p>
            Each year, <b>#INCLUDE</b> unveils a majestic theme, seamlessly
            weaving technical prowess with creative expression...
          </p>
          <Link to="/about"><button className="about-card-btn">Read More</button></Link>
        </div>
      </div>
    </div>
  );
}

export default AboutHome;
