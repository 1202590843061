import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import ClubCard from '../components/ClubCard/ClubCard'
import Aprameya from "../assets/aprameya.jpg";
import Broadband from "../assets/broadband.jpg";
import DesignCircle from "../assets/dc.jpg";
import Expedite from "../assets/expedite.jpg";
import Garuda from "../assets/garuda.jpg";
import Gdsc from "../assets/gdsc.jpg";
import Intel from "../assets/intel.jpg";
import Kognitiv from "../assets/kognitiv.jpg";
import Mayavi from "../assets/mayavi.jpg";
import Megha from "../assets/megha.jpg";
import Rpa from "../assets/rpa.jpg";
import Sea from "../assets/sea.jpg";
import Socc from '../assets/socc.jpg';
import Sods from "../assets/sods.jpg";
import Trailblazers from "../assets/Trialblazers.jpg";
import Whitehat from "../assets/whitehat.jpg";
import Footer from '../components/Footer/Footer';

function Clubs() {
  return (
    <div>
        <Navbar />
        <br></br>
        {/* <h1 style={{ textAlign: 'center', color: 'white' }}>Technology Clubs are yet to be added</h1> */}
        <div className="clubcards-list">
        <ClubCard logo={Aprameya} link="https://aprameya.netlify.app/" />
        <ClubCard logo={Broadband} link="https://broadbandklef.vercel.app/" />
        <ClubCard logo={DesignCircle} link="https://design-circle-d34c17.webflow.io/" />
        <ClubCard logo={Expedite} link="https://klexpedite.vercel.app/"/>
        </div>
        <div className="clubcards-list">
        <ClubCard logo={Garuda} link="https://klgaruda.mystrikingly.com/" />
        <ClubCard logo={Gdsc} link="https://gdscklef.netlify.app/"/>
        <ClubCard logo={Intel} link="https://dev-intelclub.pantheonsite.io/" />
        <ClubCard logo={Kognitiv} link="https://kognitivclub.tech/" />
        </div>
        <div className="clubcards-list">
        <ClubCard logo={Mayavi} link="https://klefmayavi.org/" />
        <ClubCard logo={Megha} link="https://megha21.netlify.app/" />
        <ClubCard logo={Rpa} link="https://rpakl.github.io/rpa.kl/" />
        <ClubCard logo={Sea} link="https://klsea.netlify.app/" />
        </div>
        <div className="clubcards-list">
        <ClubCard logo={Socc} link="https://soccweb.vercel.app/" />
        <ClubCard logo={Sods} link="https://klsods.netlify.app/" />
        <ClubCard logo={Trailblazers} link="https://kltrailblazers.netlify.app/" />
        <ClubCard logo={Whitehat} link="https://whitehathackers.tech/" />
        </div>
        <Footer />
    </div>
  )
}

export default Clubs