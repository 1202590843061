import React from 'react';
import "./Register.css";
import Navbar from '../components/Navbar/Navbar';

function Register() {
  return (
    <div>
      <Navbar />
      <div className="registration-info">
        <br></br>
        <br></br>
        <br></br>
        <h1 style={{ color: "#fff", fontSize: "30px" }}>Registration for #include</h1>
        <br></br>
        <p style={{ color: "#fff", fontSize: "20px" }}>Registration for #include is through the Student Tribe app.</p>
        <p style={{ color: "#fff", fontSize: "20px" }}>Download the app from the links below and sign up to participate!</p>
      </div>
      {/* Banner Image */}
      <div className="banner-image">
        <img src="https://i.ibb.co/cNM4fJx/st.png" alt="Banner" />
      </div>
      <div className="download-section">
        <p>Download from:</p>
        <a href="https://apps.apple.com/us/app/student-tribe/id1362552730" target="_blank" rel="noopener noreferrer">
          <img src="https://themexriver.com/appilo-theme/applanding-08/wp-content/uploads/sites/61/2022/03/app-store.png" alt="App Store" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.tdevelopers.stumagz" target="_blank" rel="noopener noreferrer">
          <img src="https://themexriver.com/appilo-theme/applanding-08/wp-content/uploads/sites/61/2022/03/play.png" alt="Play Store" />
        </a>
      </div>
    </div>
  );
}

export default Register;
