import React from "react";
import Marquee from "react-fast-marquee";
import "./ScrollHome.css";

function ScrollHome() {
  return (
    <div className="home-gallery">
      <div className="home-gallery-data">
        <Marquee speed={50} behavior="scroll" direction="left" scrollamount="8" className="marquee">
        <img src="https://i.ibb.co/k1sYCKW/image4-c11beb595eb0ba49e9f3.jpg" alt="gallery" />
          <img src="https://i.ibb.co/nmVf0B5/image5-ac4c211d7ab5e29a6412.jpg" alt="gallery" />
          <img src="https://i.ibb.co/Rbyk5fB/hod1.jpg" alt="gallery" />
          <img src="https://i.ibb.co/Wfz7JQw/hod.jpg" alt="gallery" />
          <img src="https://i.ibb.co/NyThy8N/prof.jpg" alt="gallery" />
        </Marquee>
      </div>
    </div>
  )
}

export default ScrollHome