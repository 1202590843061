import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import SponsorCard from '../components/SponsorCard/SponsorCard';
import './Sponsors.css'; // Import a CSS file for styling if needed
import Footer from '../components/Footer/Footer';

function Sponsors() {
  return (
    <div>
      <Navbar />
      {/* Title Sponsor */}
      <div className="sponsor-section">
        <h2 className="section-title title-sponsor">Title Sponsor</h2>
        <SponsorCard sponsorType="title" />
      </div>

      {/* Platinum Sponsors */}
      <div className="sponsor-section">
        <h2 className="section-title platinum-title">Platinum Sponsors</h2>
        <div className="sponsor-container">
          <SponsorCard sponsorType="platinum" />
          <SponsorCard sponsorType="platinum" />
          <SponsorCard sponsorType="platinum" />
        </div>
      </div>

      {/* Gold Sponsors */}
      <div className="sponsor-section">
        <h2 className="section-title gold-title">Gold Sponsors</h2>
        <div className="sponsor-container">
          <SponsorCard sponsorType="gold" />
          <SponsorCard sponsorType="gold" />
          <SponsorCard sponsorType="gold" />
        </div>
      </div>

      {/* Silver Sponsors */}
      <div className="sponsor-section">
        <h2 className="section-title silver-title">Silver Sponsors</h2>
        <div className="sponsor-container">
          <SponsorCard img="https://i.ibb.co/V2v6htj/gongora-2.png" sponsorType="silver" />
          <SponsorCard sponsorType="silver" />
          <SponsorCard sponsorType="silver" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Sponsors;
