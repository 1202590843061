import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import 'boxicons';
import logo from '../../assets/logo.png';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };
   

  return (
    <div className='navbar-container'>
      <div className="header">
        <Link to="/" className="logo">
          <img src={logo} alt='#include' />
        </Link>
        <box-icon name='menu' color='white' onClick={toggleNavbar}></box-icon>
        <nav className={`navbar ${isActive ? 'active' : ''}`}>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/events">Events</Link>
          <Link to="/team">Team</Link>
          <Link to="/gallery">Gallery</Link>
          <Link to="/clubs">Technology Clubs</Link>
          <Link to="/sponsors">Sponsors</Link>
          <Link to="/register">Register</Link>
        </nav>
      </div>
      <div className={`navBg ${isActive ? 'active' : ''}`}></div>
    </div>
  );
};

export default Navbar;
