import React, { useEffect } from 'react';
import './Loader.css';
import loader from "../../assets/loader.png"

const Loader = () => {
  useEffect(() => {
    showLoader();

    // Hide the loader after 3 seconds (adjust the time as needed)
    const timeout = setTimeout(() => {
      hideLoader();
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const showLoader = () => {
    const loaderContainer = document.querySelector('.loader-container');
    if (loaderContainer) {
      loaderContainer.style.display = 'flex';
    }
  };

  const hideLoader = () => {
    const loaderContainer = document.querySelector('.loader-container');
    if (loaderContainer) {
      loaderContainer.style.display = 'none';
    }
  };

  return (
    <div className='loader-container'>
    <div className='animation move'>Compiling.....</div>
    <div className='macbook'>
      <div className='inner'>
        <div className='screen'>
          <div className='face-one'>
            <div className='camera'></div>
            <div className='display'>
              <div className='shade'></div>
              <img className="screen-logo" src={loader} alt="Screen Logo" />
            </div>
            <span>MacBook Air</span>
          </div>
        </div>
        <div className='macbody'>
          <div className='face-one'>
            <div className='touchpad'></div>
            <div className='keyboard'>
              {/* Your key elements go here */}
              {[...Array(60)].map((_, index) => (
                <div key={index} className='key'></div>
              ))}
              {[...Array(8)].map((_, index) => (
                <div key={index} className='key f'></div>
              ))}
            </div>
          </div>
          <div className='pad one'></div>
          <div className='pad two'></div>
          <div className='pad three'></div>
          <div className='pad four'></div>
        </div>
      </div>
      <div className='shadow'></div>
    </div>
  </div>
  );
};

export default Loader;
