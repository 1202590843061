import React from "react";
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Events from './pages/Events';
import Gallery from './pages/Gallery';
import Team from './pages/Team';
import Clubs from './pages/Clubs';
import Sponsors from './pages/Sponsors';
import Loader from "./components/Loader/Loader";
import Register from "./pages/Register";


function App() {
  return (
    <div>
      <Loader />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/events" element={<Events />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/team" element={<Team />} />
          <Route path="/clubs" element={<Clubs />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/register" element={<Register />} />
      </Routes>
    </div>
  );
}

export default App;
