import React, { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import TeamCard from "../components/TeamCard/TeamCard";
import "./Team.css";
import Footer from "../components/Footer/Footer";

function Team() {
  const [selectedCommittee, setSelectedCommittee] =
    useState("Zero Order Chief");
  const [activeCommittee, setActiveCommittee] = useState("Zero Order Chief");

  // Function to filter cards based on the selected committee
  const filterCards = (committee) => {
    setSelectedCommittee(committee);
    setActiveCommittee(committee);
  };

  // Array to store cards with details and committee assignments
  const cards = [
    {
      id: 1,
      name: "Mahidhar Seelamneni",
      role: "Chief Executive",
      committee: "Zero Order Chief",
      img: "https://i.ibb.co/6yvF1Vj/mahi.jpg",
    },
    {
      id: 2,
      name: "Sreeram Tirumala",
      role: "Secretary",
      committee: "Zero Order Chief",
      img: "https://i.ibb.co/hmyyY70/sree-ram.jpg",
    },
    {
      id: 3,
      name: "Adapa Viresh",
      role: "Treasurer",
      committee: "Zero Order Chief",
      img: "https://i.ibb.co/7K4zMw2/viresh.jpg",
    },
    {
      id: 4,
      name: "Krishna Vamsi",
      role: "Chief",
      committee: "Designing Drafting and Broadcasting",
      img: "https://i.ibb.co/7tSVPxx/vamsi.jpg",
    },
    {
      id: 5,
      name: "N karthikeya",
      role: "Core",
      committee: "Designing Drafting and Broadcasting",
      img: "https://i.ibb.co/PW86m5f/karthik.jpg",
    },
    {
      id: 6,
      name: "V Dilip Krishna",
      role: "Core",
      committee: "Designing Drafting and Broadcasting",
      img: "https://i.ibb.co/ts1yjZ0/dileep.jpg",
    },
    {
      id: 44,
      name: "Jashu",
      role: "Core",
      committee: "Designing Drafting and Broadcasting",
      img: "https://i.ibb.co/ch1Cps2/jashu.jpg",
    },
    {
      id: 7,
      name: "K Anil Reddy",
      role: "Core",
      committee: "Designing Drafting and Broadcasting",
      img: "https://i.ibb.co/K9YJwc2/anil.jpg",
    },
    {
      id: 8,
      name: "Praharshini",
      role: "Core",
      committee: "Designing Drafting and Broadcasting",
      img: "https://i.ibb.co/9HW33Nf/tinku.jpg",
    },
    {
      id: 9,
      name: "Rolla Anil Kumar",
      role: "Chief",
      committee: "Cultural and Creative Arts",
      img: "https://i.ibb.co/zm49gcr/rolla-anil.jpg",
    },
    {
      id: 10,
      name: "K Bhuvana",
      role: "Core",
      committee: "Cultural and Creative Arts",
      img: "https://i.ibb.co/TtLTZd4/bhuvana.jpg",
    },
    {
      id: 11,
      name: "T Charmi Padmaja",
      role: "Core",
      committee: "Cultural and Creative Arts",
      img: "https://i.ibb.co/DM8Z8Xx/charmi.jpg",
    },
    {
      id: 12,
      name: "Siri Tummala",
      role: "Core",
      committee: "Cultural and Creative Arts",
      img: "https://i.ibb.co/gFmCbCx/siri.jpg",
    },
    {
      id: 13,
      name: "Pavani Kataru",
      role: "Core",
      committee: "Cultural and Creative Arts",
      img: "https://i.ibb.co/ypyh8ym/pavani.jpg",
    },
    {
      id: 14,
      name: "Sayyad shoaib afridi",
      role: "Core",
      committee: "Cultural and Creative Arts",
      img: "https://i.ibb.co/Xzn43vP/shoiab.jpg",
    },
    {
      id: 15,
      name: "A Dheeraj Kumar",
      role: "Core",
      committee: "Cultural and Creative Arts",
      img: "https://i.ibb.co/02vtQBP/dheeraj.jpg",
    },
    {
      id: 16,
      name: "Nagumothu Varshitha",
      role: "Chief",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/tcHZdcJ/varshitha-1.jpg",
    },
    {
      id: 17,
      name: "T Sohan",
      role: "Core",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/6stwp2S/sohan.jpg",
    },
    {
      id: 18,
      name: "Saranu Sai rakesh",
      role: "Core",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/0mPVcdM/rakesh.jpg",
    },
    {
      id: 19,
      name: "Varshitha madasi",
      role: "Core",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/4Z8tpp2/varshitha.jpg",
    },
    {
      id: 20,
      name: "G Vijaya Tejaswari",
      role: "Core",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/LQHPgbH/TEJASWRI.jpg",
    },
    {
      id: 21,
      name: "Karthik Venkat Sai",
      role: "Core",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/Q8gnqXp/krthik.jpg",
    },
    {
      id: 22,
      name: "Bhargav chiramdasu",
      role: "Core",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/8X1vLct/bhargav.jpg",
    },
    {
      id: 23,
      name: "Phaneendra nikhil",
      role: "Core",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/CQj2hT6/nikhil.jpg",
    },
    {
      id: 24,
      name: "Mohana",
      role: "Core",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/QCSk6rG/mohana.jpg",
    },
    {
      id: 25,
      name: "Y HARSHINI",
      role: "Core",
      committee: "Hospitality and Non-Tech",
      img: "https://i.ibb.co/ypdNXY2/HARSHINI.jpg",
    },
    {
      id: 26,
      name: "Gayathri Thotakura",
      role: "Chief",
      committee: "Registration & Certification and Public Relations",
      img: "https://i.ibb.co/HHY51DK/gayatri.jpg",
    },
    {
      id: 27,
      name: "P Manoj Teja",
      role: "Core",
      committee: "Registration & Certification and Public Relations",
      img: "https://i.ibb.co/GCXJm78/Manoj.jpg",
    },
    {
      id: 28,
      name: "Vasanth",
      role: "Core",
      committee: "Registration & Certification and Public Relations",
      img: "https://i.ibb.co/7QqGsgk/vasanth.jpg",
    },
    {
      id: 29,
      name: "Taraswi Jampana",
      role: "Core",
      committee: "Registration & Certification and Public Relations",
      img: "https://i.ibb.co/r3DcH4C/taraswi.jpg",
    },
    {
      id: 30,
      name: "Ganta ram praveen",
      role: "Core",
      committee: "Registration & Certification and Public Relations",
      img: "https://i.ibb.co/0VHnGrH/praveen.jpg",
    },
    {
      id: 31,
      name: "M Sumanth Naidu",
      role: "Chief",
      committee: "Sponsorship and Human Resources & Info",
      img: "https://i.ibb.co/B2qQy6p/sumanth.jpg",
    },
    {
      id: 32,
      name: "Manish",
      role: "Core",
      committee: "Sponsorship and Human Resources & Info",
      img: "https://i.ibb.co/W68Pb78/manish.jpg",
    },
    {
      id: 33,
      name: "Chaitanya Snehitha Kanagala ",
      role: "Core",
      committee: "Sponsorship and Human Resources & Info",
      img: "https://i.ibb.co/zV1tkt1/snehitha.jpg",
    },
    {
      id: 34,
      name: "Digvijay Sai G",
      role: "Core",
      committee: "Sponsorship and Human Resources & Info",
      img: "https://i.ibb.co/PFMwNnJ/vijay.jpg",
    },
    {
      id: 35,
      name: "Arramali Dakshayani",
      role: "Core",
      committee: "Sponsorship and Human Resources & Info",
      img: "https://i.ibb.co/FKR4Sn0/Dakshni.jpg",
    },
    {
      id: 36,
      name: "G Varun Chand",
      role: "Core",
      committee: "Sponsorship and Human Resources & Info",
      img: "https://i.ibb.co/MZzv3Rs/vanun.jpg",
    },
    {
      id: 37,
      name: "SVS Sujith reddy",
      role: "Chief",
      committee: "Logistics and Stage Management & Stalls",
      img: "https://i.ibb.co/KFG4n9x/sujith.jpghttps://i.ibb.co/wsX4v4q/harsha.jpg",
    },
    {
      id: 38,
      name: "AKKURI RAMAKRISHNA SRINIVAS ",
      role: "Core",
      committee: "Logistics and Stage Management & Stalls",
      img: "https://i.ibb.co/tqJxn72/srinivas.jpg",
    },
    {
      id: 39,
      name: "KALAPUREDDI S G S V PRAKASH RAJ",
      role: "Core",
      committee: "Logistics and Stage Management & Stalls",
      img: "https://i.ibb.co/3CsGQSC/sai.jpg",
    },
    {
      id: 40,
      name: "Dubaguntla Sandeep",
      role: "Core",
      committee: "Logistics and Stage Management & Stalls",
      img: "https://i.ibb.co/4m8TsW3/sandeep.jpg",
    },
    {
      id: 41,
      name: "Ala Nandu",
      role: "Core",
      committee: "Logistics and Stage Management & Stalls",
      img: "https://i.ibb.co/jDpnHXq/nandu.jpg",
    },
    {
      id: 42,
      name: "NAVULURI LOKESH",
      role: "Core",
      committee: "Logistics and Stage Management & Stalls",
      img: "https://i.ibb.co/MN40QJB/lokesh.jpg",
    },
    {
      id: 43,
      name: "N Harshavardhan",
      role: "Chief",
      committee: "Technical Events and Website",
      img: "https://i.ibb.co/wsX4v4q/harsha.jpg",
    },
    {
      id: 46,
      name: "Shanmukh",
      role: "Core",
      committee: "Technical Events and Website",
      img: "https://i.ibb.co/nkvGY4d/SHANMUKH.jpg",
    },
    {
      id: 47,
      name: "G.Subramya Murali Kowshik",
      role: "Core",
      committee: "Technical Events and Website",
      img: "https://i.ibb.co/Ybm8CfM/kowsik.jpg",
    },
    {
      id: 48,
      name: "M.Venkata Shayam",
      role: "Core",
      committee: "Technical Events and Website",
      img: "https://i.ibb.co/5cMv7Nb/shayam.jpg",
    },
    {
      id: 49,
      name: "Baba Afrid S",
      role: "Web Master",
      committee: "Web Master",
      img: "https://i.ibb.co/M58PKrQ/afrid.jpg",
    },
    {
      id: 50,
      name: "Naga Gopi Chappidi",
      role: "Core",
      committee: "Technical Events and Website",
      img: "https://i.ibb.co/yFX30x7/naga-gopi.jpg",
    },
    {
      id: 51,
      name: "Rohini Gupta Pandiri",
      role: "Core",
      committee: "Technical Events and Website",
      img: "https://i.ibb.co/VDVrYqy/rohini.jpg",
    },
  ];

  // Function to get cards for the selected committee
  const getFilteredCards = () => {
    return selectedCommittee
      ? cards.filter((card) => card.committee === selectedCommittee)
      : cards;
  };

  // Array to store committee names
  const committees = [
    "Zero Order Chief",
    "Web Master",
    "Designing Drafting and Broadcasting",
    "Cultural and Creative Arts",
    "Hospitality and Non-Tech",
    "Registration & Certification and Public Relations",
    "Sponsorship and Human Resources & Info",
    "Logistics and Stage Management & Stalls",
    "Technical Events and Website",
  ];

  return (
    <div>
      <Navbar />
      <br />
      <div className="committee-buttons">
        {/* Buttons for committee selection */}
        {committees.map((committee, index) => (
          <button
            key={index}
            onClick={() => filterCards(committee)}
            className={activeCommittee === committee ? "active" : ""}
          >
            {committee}
          </button>
        ))}
      </div>
      <div className="committee-selector">
        {/* Dropdown for committee selection */}
        <select
          value={selectedCommittee}
          onChange={(e) => filterCards(e.target.value)}
        >
          {committees.map((committee, index) => (
            <option key={index} value={committee}>
              {committee}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div className="team-card-container">
        {/* Render team cards based on the selected committee */}
        {getFilteredCards().map((card) => (
          <TeamCard
            key={card.id}
            img={card.img}
            name={card.name}
            role={card.role}
          />
        ))}
      </div>
    </div>
  );
}

export default Team;
