// Home.js
import React from "react";
import Navbar from "../components/Navbar/Navbar";
import "./Home.css";
import MainHome from "../components/MainHome/MainHome";
import AboutHome from "../components/AboutHome/AboutHome";
import CountDown from "../components/CountDown/CountDown";
import Footer from "../components/Footer/Footer";
import ScrollHome from "../components/ScrollHome/ScrollHome";


function Home() {
  return (
    <div>
      <Navbar />
      <MainHome />
      <CountDown targetDate="2024-03-22 00:00:00" />
      <br></br>
      <AboutHome />
      <ScrollHome />
      <br></br>
      <Footer />
    </div>
  );
}

export default Home;
