import React, { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import "./Events.css";

function Events() {
  const [showTechnicalEvents, setShowTechnicalEvents] = useState(true);
  const [activeButton, setActiveButton] = useState("technical");

  const toggleEventsDisplay = (isTechnical) => {
    setShowTechnicalEvents(isTechnical);
    setActiveButton(isTechnical ? "technical" : "nonTechnical");
  };

  // Define your events array

  const events = [
    {
      id: 1,
      name: "DCUB3(Design, Develop, Deploy)",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C425",
      category: "Technical",
      desc: "Participants need to conceive, design, develop, and deploy innovative tech solutions within a limited time frame.",
      core: "Shanmukh",
      coreno: "9490804533",
      sil: "80",
      pic: "https://i.ibb.co/ypqnsFG/DCU3.jpg",
    },
    {
      id: 2,
      name: "Game Dev using Scratch",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C211",
      category: "Technical",
      desc: "Participants need to develop a game or animated video using scratch software.  ",
      core: "M.Venkata Shyam",
      coreno: "6301498379",
      sil: "80",
      pic: "https://i.ibb.co/t3ctHjD/GAME-DEV.jpg",
    },
    {
      id: 3,
      name: "Innovation Prototype challenge",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C310",
      category: "Technical",
      desc: "Participants need to design a prototype of their wish that must be innovative",
      core: "Ch.Vinay",
      coreno: "7075165666",
      sil: "80",
      pic: "https://i.ibb.co/c6NqqDx/Innovation.jpg",
    },
    {
      id: 4,
      name: "Battle of Bytes",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C411",
      category: "Technical",
      desc: "Participants need to solve algorithmic and coding challenges to demonstrate their problem-solving skills. ",
      core: "Abhay",
      coreno: "9381711263",
      sil: "80",
      pic: "https://i.ibb.co/Q8J1r4V/battle-of-bytes.jpg",
    },
    {
      id: 5,
      name: "Schema Craft",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C210",
      category: "Technical",
      desc: "Participants need to build a database schema on it including tables, relationships, and queries.",
      core: "G.Subramanya Murali Kowsik",
      coreno: "8919393466",
      sil: "80",
      pic: "https://i.ibb.co/X3Mwt35/Scheme.jpg",
    },
    {
      id: 6,
      name: "Webverse",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C407",
      category: "Technical",
      desc: "A competition challenging teams to develop innovative web applications addressing specific real-world problems using desired tech stacks. ",
      core: "Shanmukh",
      coreno: "9490804533",
      sil: "80",
      pic: "https://i.ibb.co/vLfg7r8/harsha-2.jpg",
    },
    {
      id: 7,
      name: "You vs Team",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C311",
      category: "Technical",
      desc: "A solo vs trio competition where a solo person challenges the trio to solve the problem and vice versa. ",
      core: "M.Venkata Shyam",
      coreno: 6301498379,
      sil: "80",
      pic: "https://i.ibb.co/ZdDkfJb/you-vs-team.jpg",
    },
    {
      id: 8,
      name: "Code Earth",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C510",
      category: "Technical",
      desc: "Code Earth is a competive programming contest where the participants need to complete the assigned tasks.",
      core: "Shanmukh",
      coreno: "9490804533",
      sil: "80",
      pic: "https://i.ibb.co/kKwqyWD/code-earth.jpg",
    },
    {
      id: 9,
      name: "Contraption to Convenience",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C506",
      category: "Technical",
      desc: "Participants need to identify a few features in real life objects and gadgets which has unnecessary features and make it more optimal for daily use.",
      core: "Ch.Vinay",
      coreno: "7075165666",
      sil: "80",
      pic: "https://i.ibb.co/FKSvp7s/Contraption-to-Convenienceposter.jpg",
    },
    {
      id: 10,
      name: "Hack Overflow",
      date: "03-22-2024",
      time: "09:30AM - 12:30PM",
      venue: "C522",
      category: "Technical",
      desc: "HACKOVERFLOW is a Capture The Flag (CTF) competition where teams of two work together to solve cybersecurity challenges across multiple rounds. ",
      core: "Abhay",
      coreno: "9381711263",
      sil: "80",
      pic: "https://i.ibb.co/2PQCG6W/hack-overflow.jpg",
    },
    {
      id: 11,
      name: "Copy Paste",
      date: "22-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C325",
      category: "Technical",
      desc: "Participants need to develop the same or similar functioning code in the given time and fastest qualifies to next rounds. ",
      core: "G.Subramanya Murali Kowsik ",
      coreno: "8919393466",
      sil: "80",
      pic: "https://i.ibb.co/BzQ9bpz/copy-paste.jpg",
    },
    {
      id: 12,
      name: "The Code Race",
      date: "22-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C507",
      category: "Technical",
      desc: "participants will be given a code with some parts of the code missing in it and it's output will be given, they have to finish the code ",
      core: "M.Venkata Shyam",
      coreno: "6301498379",
      sil: "80",
      pic: "https://i.ibb.co/wCfkwdq/The-Code-Race.jpg",
    },
    {
      id: 13,
      name: "Data Quest",
      date: "22-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C410",
      category: "Technical",
      desc: "Data Quest is a data analysis competition where participants analyse datasets to derive insights and solve real-world problems.         ",
      core: "G.Subramanya Murali Kowsik",
      coreno: "8919393466",
      sil: "80",
      pic: "https://i.ibb.co/6BtKRF2/DATAQUEST.jpg",
    },
    {
      id: 14,
      name: "Logic quest",
      date: "22-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C211",
      category: "Technical",
      desc: "The participants need to find out the output in the form of a quiz in the area of logic building and Coding area        ",
      core: "Shanmukh",
      coreno: "9490804533",
      sil: "80",
      pic: "https://i.ibb.co/JFqyLbk/Logic-Quest.jpg",
    },
    {
      id: 15,
      name: "Opti Code Challenge",
      date: "22-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C511",
      category: "Technical",
      desc: "Participants needs to optimize the Time Complexity and need to modify the code in such a way that the Time Complexity of the Code should match with their Given Time Complexity",
      core: "Abhay",
      coreno: "9381711263",
      sil: "80",
      pic: "https://i.ibb.co/dGxJtm1/photo-2024-03-20-18-23-27.jpg",
    },
    {
      id: 16,
      name: "Buddy Bot ",
      date: "23-03-2024",
      time: "09:30 AM - 12:30 PM",
      venue: "C225",
      category: "Technical",
      desc: "Buddy bot is an event where the Participants need to Create a chatbot on the given statement",
      core: "G.Subramanya Murali Kowsik",
      coreno: "8919393466",
      sil: "80",
      pic: "https://i.ibb.co/Kx4dWPm/Buddy-Bot.jpg",
    },
    {
      id: 17,
      name: "Make a dataset",
      date: "23-03-2024",
      time: "09:30 AM - 12:30 PM",
      venue: "C506",
      category: "Technical",
      desc: "Participants need to build a data set for the requirement as given by panel.",
      core: "G.Subramanya Murali Kowsik",
      coreno: "8919393466",
      sil: "80",
      pic: "https://i.ibb.co/q0DTM28/make-a-dataset.jpg",
    },
    {
      id: 18,
      name: "My Interface ",
      date: "23-03-2024",
      time: "09:30 AM - 12:30 PM",
      venue: "C425",
      category: "Technical",
      desc: "Participants need to design UI for the given theme. The best designer will be selected and qualified.",
      core: "Shanmukh",
      coreno: "9490804533",
      sil: "80",
      pic: "https://i.ibb.co/2Phs40y/My-Interface.jpg",
    },

    {
      id: 19,
      name: "Source Seekers",
      date: "23-03-2024",
      time: "09:30 AM - 12:30 PM",
      venue: "C210",
      category: "Technical",
      desc: "Finding the Open Source Projects from the Internet and Explaining what they are and how can we modify them        ",
      core: "Ch.Vinay",
      coreno: "7075165666        ",
      sil: "80",
      pic: "https://i.ibb.co/f0Wz0mY/source-seekers.jpg",
    },
    {
      id: 20,
      name: "Debugging Dash",
      date: "23-03-2024",
      time: "09:30 AM - 12:30 PM",
      venue: "C406",
      category: "Technical",
      desc: "Debugging Dash is a thrilling coding challenge where participants showcase their problem-solving prowess under pressure.",
      core: "Ch.Vinay",
      coreno: "7075165666        ",
      sil: "80",
      pic: "https://i.ibb.co/yF4vn7Q/Debugging-dush.jpg",
    },
    {
      id: 21,
      name: "Techniverse",
      date: "23-03-2024",
      time: "09:00 AM - 12:30 PM",
      venue: "C425",
      category: "Technical",
      desc: "The Participants Need to prepare a Document on their Preferred Specialization and take a session on their Preferred Session",
      core: "Ch.Vinay",
      coreno: "7075165666",
      sil: "80",
      pic: "https://i.ibb.co/K7s1L1t/Techniverse.jpg",
    },
    {
      id: 22,
      name: "Fusion 360 Tech Showcase",
      date: "23-03-2024",
      time: "09:00 AM - 12:30 PM",
      venue: "C411",
      category: "Technical",
      desc: "We provide a design theme and ask participants to create a design based on that theme using fusion",
      core: "Abhay",
      coreno: "9381711263",
      sil: "80",
      pic: "https://i.ibb.co/7vCX1gS/Fusion-360-Tech-Showcase.jpg",
    },
    {
      id: 23,
      name: "CryptoZombie",
      date: "23-03-2024",
      time: "09:00 AM - 12:30 PM",
      venue: "C311",
      category: "Technical",
      desc: "participants will do the complete module of cryptozombie and the one finish first declared winner.",
      core: "M.Venkata Shyam",
      coreno: "6301498379",
      sil: "80",
      pic: "https://i.ibb.co/g3Nw5sw/Crypto-Zombie.jpg",
    },
    {
      id: 24,
      name: "Open Book Challenge",
      date: "23-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C506",
      category: "Technical",
      desc: "We will give a hard-problem statements that needs research.the participants will explore and code the given scenario as a open-book challenge",
      core: "M.Venkata Shyam",
      coreno: "6301498379",
      sil: "80",
      pic: "https://i.ibb.co/r2LfrP7/Open-Book-Challenge.jpg",
    },
    {
      id: 25,
      name: "Quick Build",
      date: "23-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C507",
      category: "Technical",
      desc: "The Participants need to build a website using Low Code and need to deploy it if they completed the Building of website. ",
      core: "Shanmukh",
      coreno: "9490804533",
      sil: "80",
      pic: "https://i.ibb.co/wB7jB2m/quick-build.jpg",
    },
    {
      id: 26,
      name: "Core Coders",
      date: "23-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C525",
      category: "Technical",
      desc: "The participants needs to write the actual code of inbuilt packages or libraries as given by panel in their preferred language",
      core: "G.Subramanya Murali Kowsik",
      coreno: "8919393466",
      sil: "80",
      pic: "https://i.ibb.co/dpMsX3F/Core-Coders.jpg",
    },
    {
      id: 27,
      name: "OverFlow Resolved",
      date: "23-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C510",
      category: "Technical",
      desc: "The participants need to find the real world problems from stack overflow and they need to find the correct solutions",
      core: "Ch.Vinay",
      coreno: "7075165666",
      sil: "80",
      pic: "https://i.ibb.co/T0JHnCX/Overflow-Resolved.jpg",
    },
    {
      id: 28,
      name: "Tech Triumph",
      date: "23-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C522",
      category: "Technical",
      desc: "The Participants should Guess the Output from the given options",
      core: "Abhay",
      coreno: "9381711263",
      sil: "80",
      pic: "https://i.ibb.co/3fwKv7r/Tech-Triumpt.jpg",
    },

    {
      id: 29,
      name: "UX Gesture",
      date: "23-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C210",
      category: "Technical",
      desc: "The participants will mimic the features as given by the panel by drawing them as a pretotype on board",
      core: "Abhay",
      coreno: "9381711263",
      sil: "80",
      pic: "https://i.ibb.co/Db7yk2n/UX-Gesture.jpg",
    },
    {
      id: 30,
      name: "Web VR",
      date: "23-03-2024",
      time: "02:00 PM - 05:00 PM",
      venue: "C325",
      category: "Technical",
      desc: "Creating a 3D Website Using Co-Spaces. Co-Spaces is one of the Website which can be used to create a VR spaces with Low code or using Drag and Drop.",
      core: "M.Venkata Shyam",
      coreno: "6301498379",
      sil: "80",
      pic: "https://i.ibb.co/wRw2S5z/web-vr.jpg",
    },
    {
      id: 31,
      name: "Hand Wrestling",
      date: "22-03-2024",
      time: "9:00 AM - 12:00 PM",
      venue: "C Lobby",
      category: "Non-Technical",
      desc: "Unleash your inner warrior in the ultimate hand-to-hand showdown!",
      core: "Harshini",
      coreno: "81790 78459",
      sil: "30",
      pic: "https://i.ibb.co/tJySm7f/hand-wrestling.jpg",
    },
    {
      id: 32,
      name: "University unpluged",
      date: "22-03-2024",
      time: "9:00 AM - 11:00 AM",
      venue: "C121",
      category: "Non-Technical",
      desc: "Seize the stage and share your voice at our Open Mic night! ",
      core: "Bhargav ch",
      coreno: "9491737028",
      sil: "60",
      pic: "https://i.ibb.co/m96gD72/university-unplagged.jpg",
    },
    {
      id: 33,
      name: "BGMI",
      date: "22-03-2024",
      time: "9:00 AM - 12:00 PM",
      venue: "C525",
      category: "Non-Technical",
      desc: "Prepare for an adrenaline-fueled showdown in our BGMI Contest! ",
      core: "Phaneendra Nikhil",
      coreno: "9100411666",
      sil: "80",
      pic: "https://i.ibb.co/tYSHFw5/pubg.jpg",
    },
    {
      id: 34,
      name: "Don't Laugh",
      date: "22-03-2024",
      time: "4:00 PM - 5:00 PM",
      venue: "C423",
      category: "Non-Technical",
      desc: "Gear up for an evening of side-splitting hilarity at our Don't Laugh  event!",
      core: "Mohana",
      coreno: "9704689676",
      sil: "40",
      pic: "https://i.ibb.co/FJ0Js6q/dont-laugh.jpg",
    },
    {
      id: 35,
      name: "Creative Odessey Scribble",
      date: "22-03-2024",
      time: "10:00 AM - 12:30 PM",
      venue: "3 Floor Lab",
      category: "Non-Technical",
      desc: "Dive into doodles and laughter in our Scribble.io tournament!",
      core: "BHASHVITHA REDDY",
      coreno: "94418 48412",
      sil: "70",
      pic: "https://i.ibb.co/YNCLdJh/creative-odessy.jpg",
    },
    {
      id: 36,
      name: "PopCult Phonic",
      date: "22-03-2024",
      time: "1:30 PM - 3:30 PM",
      venue: "C525",
      category: "Non-Technical",
      desc: "Engage in the ultimate audio challenge, celebrating the magic of pop culture in an immersive event",
      core: "Harshini",
      coreno: "81790 78459",
      sil: "60",
      pic: "https://i.ibb.co/sV1XxCk/Popcult-phonic.png",
    },
    {
      id: 37,
      name: "MinuteMaster",
      date: "22-03-2024",
      time: "2:30 PM - 3:30 PM",
      venue: "C210",
      category: "Non-Technical",
      desc: "MinuteMasters Challenge shines as our branch fest's top non-tech event, spotlighting storytelling brilliance",
      core: "Mohana",
      coreno: "9704689676",
      sil: "40",
      pic: "https://i.ibb.co/pf2j8v5/minutemaster.png",
    },
    {
      id: 38,
      name: "IPL AUCTION",
      date: "22-03-2024",
      time: "2:00 PM - 4:00 PM",
      venue: "C225",
      category: "Non-Technical",
      desc: "IPL Auction Quiz Game tailored for college students! Test your cricket knowledge in an exciting quiz",
      core: "Bhargav ch",
      coreno: "9491737028",
      sil: "70",
      pic: "https://i.ibb.co/289y064/IPL-Auction.png",
    },
    {
      id: 39,
      name: "Just shoot mee",
      date: "22-03-2024",
      time: "2:00 PM - 4:30 PM",
      venue: "#",
      category: "Non-Technical",
      desc: "We invite photographers of all levels to showcase their creativity by capturing striking images",
      core: "Phaneendra Nikhil",
      coreno: "9100411666",
      sil: "60",
      pic: "https://i.ibb.co/KXbghKh/justshootme.png",
    },
    {
      id: 40,
      name: "Whacky Olympics",
      date: "22-03-2024",
      time: "9:00 AM - 12:00 PM",
      venue: "OAT",
      desc: "Create a series of unconventional and games such as three-legged races, wheelbarrow races .",
      category: "Non-Technical",
      core: "Phaneendra Nikhil",
      coreno: "91004 11666",
      sil: "80",
      pic: "https://i.ibb.co/W0V5R7R/whackyolympics.png",
    },
    {
      id: 41,
      name: "BLIND FOLD drawing",
      date: "22-03-2024",
      time: "9:00 AM - 12:00 PM",
      venue: "C  Lobby",
      category: "Non-Technical",
      desc: "Blindfold drawing is a fun and creative activity where participants are asked",
      core: "Harshini",
      coreno: "81790 78459",
      sil: "80",
      pic: "https://i.ibb.co/wSK5RY1/blindfolddrawing.png",
    },
    {
      id: 42,
      name: "LAN Game Show",
      date: "22-03-2024",
      time: "1:00 PM - 4:30 PM",
      venue: "C525",
      category: "Non-Technical",
      desc: "A LAN game tournament on PlayStation is an event where players gather in a local area network setting to compete against each other in various games on PlayStation consoles.",
      core: "Bhargav ch",
      coreno: "9491737028",
      sil: "80",
      pic: "https://i.ibb.co/syC1q0J/LAN-Game-Show.jpg",
    },
    {
      id: 43,
      name: "REPLICATE THE GESTURE",
      date: "22-03-2024",
      time: "1:00 PM - 4:30 PM",
      venue: "C lobby",
      category: "Non-Technical",
      desc: "The Gesture Replication Challenge is a unique and creative contest where participants are tasked",
      core: "BHASHVITHA REDDY",
      coreno: "94418 48412",
      sil: "30",
      pic: "https://i.ibb.co/KFtn1V7/replicatethegesture.png",
    },
    {
      id: 44,
      name: "CLAY POTERY",
      date: "22-03-2024",
      time: "1:00 PM - 4:30 PM",
      venue: "C Lobby",
      category: "Non-Technical",
      desc: "The Clay Creations Pottery Challenge is an engaging and hands-on event where participants",
      core: "Bhargav ch",
      coreno: "9491737028",
      sil: "30",
      // pic: "https://i.ibb.co/JvTGmcv/claypottery.png",
      pic: "https://i.ibb.co/KwhfVqz/clay-pottery.jpg",
    },
    {
      id: 45,
      name: "HOPSCOCH",
      date: "22-03-2024",
      time: "1:00 PM - 4:30 PM",
      venue: "Clobby",
      category: "Non-Technical",
      desc: "A journey through childhood nostalgia and playful exploration awaits in our Hopscotch event!",
      core: "BHASHVITHA REDDY",
      coreno: "94418 48412",
      sil: "30",
      pic: "https://i.ibb.co/4TSDqds/hopscotch.png",
    },
    {
      id: 55,
      name: "Guess the Anime Character",
      date: "23-03-2024",
      time: "9:00 AM - 11:00 AM",
      venue: "C425",
      category: "Non-Technical",
      desc: "Participants will engage in a fun-filled event where they'll test their knowledge of anime",
      core: "Harshini",
      coreno: "8179078459",
      sil: "80",
      pic: "https://i.ibb.co/vdnwhTs/guess-the-Anime-Character.jpg",
    },
    {
      id: 46,
      name: "ChitChat Fiesta",
      date: "23-03-2024",
      time: "9:30 AM - 11:30 AM",
      venue: "C308",
      category: "Non-Technical",
      desc: "Experience the thrill of spontaneous connections at our Chit Chat Fiesta! Dive into thought-provoking",
      core: "BHASHVITHA REDDY",
      coreno: "94418 48412",
      sil: "60",
      pic: "https://i.ibb.co/w6Mgcwy/Chit-Chat-Fiesta.jpg",
    },
    {
      id: 47,
      name: "Meme Creation",
      date: "23-03-2024",
      time: "9:30 AM - 12:00 PM",
      venue: "C309",
      category: "Non-Technical",
      desc: "Dive into the digital realm of wit and laughter at our Meme Creation event!",
      core: "Bhargav ch",
      coreno: "9491737028",
      sil: "80",
      pic: "https://i.ibb.co/3C5szgb/Meme-Creation.jpg",
    },
    {
      id: 48,
      name: "Blind food taste",
      date: "23-03-2024",
      time: "01:30 AM - 03:30 PM",
      venue: "C307",
      category: "Non-Technical",
      desc: "Let your taste buds lead the way as you explore a world of delicious surprises",
      core: "Harshini ",
      coreno: "81790 78459",
      sil: "60",
      pic: "https://i.ibb.co/0hNfshN/Blind-food-taste.jpg",
    },
    {
      id: 49,
      name: "Photo pursuit",
      date: "23-03-2024",
      time: "11:30 AM - 2:30 PM",
      venue: "C208",
      category: "Non-Technical",
      desc: "Decode mysterious images, race to campus locations, and immerse yourself in an interactive fusion of scavenger",
      core: "Mohana",
      coreno: "9704689676",
      sil: "80",
      pic: "https://i.ibb.co/NxC6C5h/Photo-pursuit.jpg",
    },
    {
      id: 50,
      name: "Buy beg or steal",
      date: "23-03-2024",
      time: "9:00 AM - 12:00 PM",
      venue: "3 Floor Lab",
      category: "Non-Technical",
      desc: "This unique event encourages participants to unleash their creativity",
      core: "BHASHVITHA REDDY",
      coreno: "9441848412",
      sil: "80",
      pic: "https://i.ibb.co/tHyT5Pr/campusquest.png",
    },
    {
      id: 51,
      name: "LikeLeader",
      date: "23-03-2024",
      time: "02:30 PM - 03:30 PM",
      venue: "C210",
      category: "Non-Technical",
      desc: "Compete to accumulate likes on Instagram posts, showcasing the captivating content of our talented participants . ",
      core: "Mohana",
      coreno: "9704689676",
      sil: "30",
      pic: "https://i.ibb.co/XbM05nc/likeleader.png",
    },
    {
      id: 52,
      name: "DETECTIVE GAME",
      date: "23-03-2024",
      time: "1:00 AM - 4:30 PM",
      venue: "4 Floor lab",
      category: "Non-Technical",
      desc: "Embark on a thrilling journey as you unravel puzzles, decode clues, and outsmart adversaries",
      core: "BHASHVITHA REDDY",
      coreno: "94418 48412",
      sil: "80",
      pic: "https://i.ibb.co/cbkqtTF/detectivegame.png",
    },
    {
      id: 53,
      name: "Bridge stack buliding using cards",
      date: "23-03-2024",
      time: "9:00 AM - 12:00 PM",
      venue: "C Lobby",
      category: "Non-Technical",
      desc: "FUN GAMEParticipants will construct sturdy and innovative structures using only playing cards. ",
      core: "Phaneendra Nikhil",
      coreno: "9100411666",
      sil: "30",
      pic: "https://i.ibb.co/nm6RKR3/Bridge-Stack-building-using-cards.png",
    },
    {
      id: 54,
      name: "FAKE NEWS CHALLENGE",
      date: "23-03-2024",
      time: "1:00 PM - 3:00 PM",
      venue: "C325",
      category: "Non-Technical",
      desc: "Participants will be presented with sets of statements, each containing two falsehoods and one genuine fact.",
      core: "Bhargav ch",
      coreno: "9491737028",
      sil: "60",
      pic: "https://i.ibb.co/LYpRPL9/fakenewschallenge.png",
    },
    {
      id: 56,
      name: "FIND THE MARBLES",
      date: "23-03-2024",
      time: "9:00 AM - 12:00 PM",
      venue: "OAT",
      category: "Non-Technical",
      desc: "FUN GAME",
      core: "Phaneendra Nikhil",
      coreno: "91004 11666",
      sil: "30",
      pic: "https://i.ibb.co/qjKpW7X/FIND-THE-MARBLES.jpg",
    },
    {
      id: 57,
      name: "ORIGAMI",
      date: "23-03-2024",
      time: "9:00 AM - 12:00 PM",
      venue: "C Lobby",
      category: "Non-Technical",
      desc: "Folded Wonders Origami Competition is a captivating event that celebrates the art of origami .",
      core: "Mohana",
      coreno: "9704689676",
      sil: "30",
      pic: "https://i.ibb.co/s5xkTSH/ORIGAMI.jpg",
    },
    {
      id: 58,
      name: "FITTNESS FUSION CHALLENGE",
      date: "23-03-2024",
      time: "9:00 AM - 12:00 PM",
      venue: "C Lobby",
      category: "Non-Technical",
      desc: "The Fitness Fusion Challenge is strength, endurance, and determination through a series of fitness challenges.",
      core: "Bhargav ch",
      coreno: "9491737028",
      sil: "30",
      pic: "https://i.ibb.co/zGDwz93/FITTNESS-FUSION-CHALLANGE.jpg",
    },
    {
      id: 59,
      name: "Upside-Down Wisdom",
      date: "23-03-2024",
      time: "1:00 PM - 4:30 PM",
      venue: "C Lobby",
      category: "Non-Technical",
      desc: "",
      core: "Harshini",
      coreno: "8179078459",
      sil: "80",
      pic: "https://i.ibb.co/Jz1QdrB/Upside-Down-Wisdom.jpg",
    },
    {
      id: 60,
      name: "MonkeyType Madness",
      date: "23-03-2024",
      time: "1:00 PM - 4:30 PM",
      venue: "C Lobby",
      category: "Non-Technical",
      desc: "",
      core: "Phaneendra Nikhil",
      coreno: "9100411666",
      sil: "30",
      pic: "https://i.ibb.co/WGdrK1Q/monkey-type-madness.jpg",
    },
  ];

  return (
    <div>
      <Navbar />
      <br />
      <h1 style={{ textAlign: "center", color: "white" }}>Events</h1>
      <br />
      <div className="toggle-buttons">
        <button
          className={activeButton === "technical" ? "active" : ""}
          onClick={() => toggleEventsDisplay(true)}
        >
          Technical
        </button>
        <button
          className={activeButton === "nonTechnical" ? "active" : ""}
          onClick={() => toggleEventsDisplay(false)}
        >
          Non-Technical
        </button>
      </div>

      <div className="container">
        {events
          .filter((event) =>
            showTechnicalEvents
              ? event.category === "Technical"
              : event.category !== "Technical"
          )
          .map((event) => {
            return (
              <div key={event.id} className="event-card">
                <img src={event.pic} className="event-card-img" alt="" />
                <div className="event-card-body">
                  <h1 className="event-card-title">Event Name: {event.name}</h1>
                  <br />
                  <p className="event-card-sub-title">Date: {event.date}</p>
                  <p className="event-card-sub-title">Time: {event.time}</p>
                  <p className="event-card-sub-title">Venue: {event.venue}</p>
                  <p className="event-card-sub-title">
                    SIL Points: {event.sil}
                  </p>
                  <br></br>
                  <p className="event-card-sub-title">
                    Description: {event.desc}
                  </p>
                  <br />
                  <p className="event-card-sub-title">
                    Core Name: {event.core}
                  </p>
                  <p className="event-card-sub-title">
                    Core Ph No: {event.coreno}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Events;
