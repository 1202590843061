import React from 'react';
import './SponsorCard.css';

const SponsorCard = ({ sponsorType, img }) => {
  return (
    <div className="body">
      <div className={`card rgb ${sponsorType}`}>
        <div className="card-image">
          <img src={img} alt=''></img>
        </div>
        <div className="card-text">
          <h2>Sponsor</h2>
        </div>
      </div>
    </div>
  );
};

export default SponsorCard;
