import React from 'react';
import "./ClubCard.css";

function ClubCard({ logo, link }) {
  const handleImageClick = () => {
    try {
      window.open(link, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error opening link:', error);
    }
  };
  

  return (
    <div className="clubcard">
      <div className="clubcard_image">
        <img src={logo} alt='' />
      </div>
      <div className="clubcontent">
      {/* <button className='club-button' onClick={handleImageClick}>Open Link</button> */}
      <div className='club-button'>
      <box-icon onClick={handleImageClick} name='link-external'></box-icon>
      </div>
      </div>
    </div>
  );
}

export default ClubCard;
