import React from 'react'
import "./TeamCard.css";

function TeamCard({role,name,img}) {
  return (
    <div className="team-card">
      <div className="team-card-content">
        <div className="team-image">
          <img src={img} alt="" />
        </div>
        {role === "Web Master" && (
          <div className="social-media">
            <a href='https://www.instagram.com/babaafrid7/' target='blank' rel="noreferrer"><box-icon name='instagram' type='logo'></box-icon></a>
            <a href='https://github.com/babaafrid/' target='blank' rel="noreferrer"><box-icon type='logo' name='github'></box-icon></a>
            <a href='https://www.linkedin.com/in/babaafrid/' target='blank' rel="noreferrer"><box-icon name='linkedin-square' type='logo'></box-icon></a>
          </div>
        )}
        {role === "Chief Executive" && (
          <div className="social-media">
            <a href='https://www.instagram.com/urstrulymahi__?igsh=MTdxNjB5aDZyY2F6dw==' target='blank' rel="noreferrer"><box-icon name='instagram' type='logo'></box-icon></a>
          </div>
        )}
        {role === "Secretary" && (
          <div className="social-media">
            <a href='https://www.instagram.com/imsreeram03?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='blank' rel="noreferrer"><box-icon name='instagram' type='logo'></box-icon></a>
          </div>
        )}
        {role === "Treasurer" && (
          <div className="social-media">
            <a href='https://www.instagram.com/viresh_adapa?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' target='blank' rel="noreferrer"><box-icon name='instagram' type='logo'></box-icon></a>
          </div>
        )}
        <div className="name-profession">
          <span className="name">{name}</span>
          <br></br>
          <span className="profession">{role}</span>
        </div>
      </div>
    </div>
  )
}

export default TeamCard