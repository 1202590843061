import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import "./Gallery.css"

import img1 from "../assets/1.JPG"
import img2 from "../assets/2.jpg"
import img3 from "../assets/3.JPG"
import img4 from "../assets/4.JPG"
import img5 from "../assets/5.jpeg"
import img6 from "../assets/6.JPG"
import img7 from "../assets/7.JPG"
import img8 from "../assets/8.JPG"
import img9 from "../assets/9.JPG"
import img10 from "../assets/10.JPG"

function Gallery() {
  return (
    <div>
        <Navbar />
      <div className="image-gallery-container">
      <div className="box">
        <span style={{ '--i': 1 }}><img src={img1} alt="Img 1" /></span>
        <span style={{ '--i': 2 }}><img src={img2} alt="Img 2" /></span>
        <span style={{ '--i': 3 }}><img src={img3} alt="Img 3" /></span>
        <span style={{ '--i': 4 }}><img src={img4} alt="Img 4" /></span>
        <span style={{ '--i': 5 }}><img src={img5} alt="Img 5" /></span>
        <span style={{ '--i': 6 }}><img src={img6} alt="Img 6" /></span>
        <span style={{ '--i': 7 }}><img src={img7} alt="Img 7" /></span>
        <span style={{ '--i': 8 }}><img src={img8} alt="Img 8" /></span>
        <span style={{ '--i': 9 }}><img src={img9} alt="Img 9" /></span>
        <span style={{ '--i': 10 }}><img src={img10} alt="Img 10" /></span>
      </div>
    </div>
    </div>
  )
}

export default Gallery