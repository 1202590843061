import React from 'react';
import './Footer.css';
import 'boxicons';

const Footer = () => {
    return (
        <footer className="footer-flex">
            <div className="footer-column">
                <p>Get in touch with us: </p>
            </div>
            <div className="footer-column">
                <ul className="socials">
                    <li className="item">
                        <a href="https://www.instagram.com/kl_hashinclude/" target='_blank' rel="noreferrer">
                            <i className="fa-brands fa-instagram icon"><box-icon type='logo' name='instagram'></box-icon></i>
                        </a>
                    </li>
                    <li className="item">
                        <a href="https://www.linkedin.com/company/kl-hashinclude/" target='_blank' rel="noreferrer">
                            <i className="fa-brands fa-linkedin icon"><box-icon name='linkedin' type='logo'></box-icon></i>
                        </a>
                    </li>
                    <li className="item">
                        <a href="https://www.youtube.com/@klhashinclude9155" target='_blank' rel="noreferrer">
                            <i className="fa-brands fa-youtube icon"><box-icon type='logo' name='youtube'></box-icon></i>
                        </a>
                    </li>
                    <li className="item">
                        <a href="https://twitter.com/kl_hashinclude" target='_blank' rel="noreferrer">
                            <i className="fa-brands fa-x-twitter icon"><box-icon type='logo' name='twitter'></box-icon></i>
                        </a>
                    </li>
                    <li className="item">
                        <a href="mailto:hashinclude@kluniversity.in">
                            <i className="fa-brands fa-mail icon"><box-icon name='envelope'></box-icon></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="footer-column">
                <p>&copy; 2024 #Include. All rights reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
